import { createSelector } from 'reselect';

import { ProductSummary } from 'models/product';
import { ReduxState } from 'ducks';

export const visiblePartnershipProductSummariesSelector = createSelector(
  (state: ReduxState) => state.server.partnershipProductSummaries.all,
  (state: ReduxState) => state.server.settings.all,
  (partnershipProductSummaries, settings) => {
    const enablePartnerProductIds: string[] = [];
    for (const supplierProduct of settings?.partnership_settings?.supplier_products || []) {
      for (const product of supplierProduct?.products || []) {
        if (product.status === 'SALE' && product.product_id) {
          enablePartnerProductIds.push(product.product_id);
        }
      }
    }
    return partnershipProductSummaries.filter((productSummary) =>
      enablePartnerProductIds.includes(productSummary.id)
    );
  }
);

export const productSummariesSelector = createSelector(
  (state: ReduxState) => state.server.productSummaries.all,
  (state: ReduxState) => state.server.partnershipProductSummaries.all,
  (productSummaries: ProductSummary[], partnershipProductSummaries: ProductSummary[]) => [
    ...productSummaries,
    ...partnershipProductSummaries,
  ]
);

export const visibleProductSummariesSelector = createSelector(
  (state: ReduxState) => state.server.productSummaries.all,
  visiblePartnershipProductSummariesSelector,
  (productSummaries, partnershipProductSummaries) => [
    ...productSummaries,
    ...partnershipProductSummaries,
  ]
);
